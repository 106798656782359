import { css } from '@emotion/react';
import { Button, DatePicker } from 'antd';
import theme from '@/styles/theme';
import styled from '@emotion/styled';

export const useStyleModal = (): {
  styleModal;
} => {
  const styleModal = css`
    width: 100% !important;
    max-width: 1332px;
    margin: 0 auto;
    .input-trigger {
      display: flex;
      justify-content: center;
    }
    .ant-dropdown {
      background-color: #fff;
    }

    .ant-modal-content {
      max-width: 1332px;
      margin: 0 auto;
    }
    .ant-spin-blur {
      opacity: 1;
    }
    .ant-modal-close {
      top: 20px;
      right: 40px;
    }
    .mb-35 {
      margin-bottom: 35px;
    }
    .current-house {
      .title-tag {
        font-size: 17px;
        font-weight: 700;
        font-family: Montserrat;
        text-align: center;
        margin-bottom: 15px;
      }
    }
    .flex-col {
      width: 100%;
      display: flex;
      flex-direction: column;
      &.disabled {
        cursor: not-allowed;
      }
    }
    .pb-80 {
      padding-bottom: 80px !important;
    }
    .mrb {
      margin-bottom: 36px;
    }
    .mrt {
      margin-top: 36px;
    }
    .flex {
      display: flex;
    }
    .w-full {
      width: 100%;
    }
    .h-full {
      height: 100% !important;
    }
    .to {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 10px;
      font-size: 14px;
      font-family: Montserrat;
      font-weight: 700;
    }
    .feature {
      display: flex;
      width: 100%;
      flex-direction: column;
    }
    .mr {
      margin-right: 17px;
    }
    .gr-unit {
      display: flex;
      align-items: center;
      width: 100%;
      .unit {
        margin-left: 15px;
        top: 25px;
        position: relative;
      }
    }
    .gr-field {
      .cal-field {
        max-width: 76px !important;
        padding-right: 0px;
        .ant-select-selector {
          padding-right: 3px !important;
          .ant-select-selection-item {
            padding-right: 3px;
          }
        }
        .ant-select-arrow {
          right: 6px;
        }
      }
      .val-field {
        width: 100%;
      }
    }
    .s-label {
      font-size: 16px;
      opacity: 1;
      font-weight: 700;
      transition-property: top, font-size, opacity;
      transition-duration: 0.1s;
      transition-timing-function: linear;
      margin-bottom: 5px;
      display: inline-block;
    }
    .divider {
      width: 100%;
      background-color: #707070;
      height: 1px;
      margin: 36px 0px;
    }
    .text-center {
      text-align: center;
    }
    .ant-modal-content {
      background: transparent;
      box-shadow: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      margin-top: 40px;
      .ant-modal-body {
        .ant-picker {
          border-radius: 5px;
          /* height: 58px; */
          width: 100%;
          border-color: #707070;
          .ant-picker-input > input {
            font-size: 14px;
          }
          &-suffix {
            margin-right: 8px;
            display: none;
          }
          &-clear {
            right: -8px;
          }
        }
        .textArea-field {
          background-color: #fff;
          min-height: 92px;
          &.h-full {
            height: 100% !important;
          }
          &.h-limit {
            height: 118px !important;
          }
        }
        form {
          .field_item {
            border-bottom: solid 1px #707070;
            &.none-border {
              border: none;
            }
            .mark {
              color: #04caae;
            }
            padding: 36px 0px;
            .title {
              h2 {
                font-family: FiraSans;
                font-size: 26px;
                font-weight: 700;
                text-align: center;
                color: #04caae !important;
                margin-bottom: 36px;
              }
              p {
                font-family: Montserrat;
                font-size: 17px;
                font-weight: normal;
                text-align: center;
              }
            }
          }

          .form-grid-area {
            margin-top: 20px;
            display: grid;
            grid-column-gap: 20px;
            grid-row-gap: 40px;
            justify-content: center;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-areas:
              'item1 item2 item3'
              'item4 item5 item3'
              'item6 item7 item3';
          }
          .form-grid {
            margin-top: 20px;
            display: grid;
            grid-auto-columns: 1fr;
            grid-column-gap: 20px;
            grid-row-gap: 40px;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: auto;
            justify-content: center;
            align-items: start;
            &.flex-end {
              align-items: end;
            }
            &.four-col {
              grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
            }
          }
          .form-flex {
            display: flex;
            justify-content: center;
            gap: 20px;
            margin-top: 40px;
          }
          .form-item-half {
            grid-column-start: span span 1;
            grid-column-end: span span 1;
            grid-row-start: span span 1;
            grid-row-end: span span 1;
            &.form-item-flex {
              display: contents;
            }
            .pin-link {
              position: relative;
            }
            .drip-pin {
              position: absolute;
              right: -70px;
              top: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              svg:first-of-type {
                margin-right: 4px;
              }
              .link-redirect {
                cursor: pointer;
                line-height: 0;
                svg {
                  font-size: 25px;
                  color: #04caae;
                  font-weight: bold;
                }
                &.refresh {
                  svg {
                    font-size: 25px;
                  }
                }
              }
            }
            .noti-broker {
              display: flex;
              margin-top: 5px;
              align-items: center;
              .form-item-error {
                margin-top: 0px;
                font-size: 16px;
                font-style: italic;
              }
            }
            &.broker {
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
              h1 {
                font-size: 22px;
                margin-left: 5px;
                font-family: FiraSans;
                font-size: 26px;
                font-weight: 700;
                color: #04caae !important;
              }
            }

            &.full-w {
              max-width: 100%;
            }
            &.disabled {
              opacity: 0.25;
            }
            &.flex-start {
              justify-content: flex-start;
            }

            .ant-checkbox-wrapper {
              margin-right: 13px;

              .ant-checkbox-inner {
                background-color: transparent;
                border-color: #fff !important;
                width: 25px;
                height: 25px;
                &:after {
                  font-size: 17px;
                  font-weight: 400;
                  border: none !important;
                  transform: rotate(0) scale(1.4) translate(-50%, -50%) !important;
                  left: 13px !important;
                  top: 51% !important;
                  color: #04caae;
                }
              }
              .ant-checkbox-checked {
                &:hover {
                  border-color: #fff !important;
                }
                &:after {
                  border-color: #fff !important;
                }
              }
              .ant-checkbox-checked .ant-checkbox-inner:after {
                /* border-color: rgb(255, 165, 53) !important; */
                border: none !important;
                content: 'x';
                font-size: 24px !important;
                left: 14px !important;
                top: 59% !important;
              }
            }
            @media only screen and (max-width: ${theme.media.phones}px) {
              grid-column-start: span 2;
              grid-column-end: span 2;
              grid-row-start: span 1;
              grid-row-end: span 1;
            }
            .ant-select-selection-placeholder {
              color: #2e024994 !important;
              font-weight: 400 !important;
            }

            .ant-input {
              /* height: 55px !important; */
              border-radius: 5px !important;
              &::placeholder {
                color: #2e024994 !important;
              }
              &.h-limit {
                height: 118px !important;
              }
              &.h-full {
                height: 100% !important;
              }
            }
            .ant-picker-input {
              &::placeholder {
                color: rgba(3, 31, 45, 0.54);
              }
            }
            .ant-input-affix-wrapper {
              height: 52px !important;
              border-radius: 5px !important;
              background-color: #fff;
              &::placeholder {
                color: rgba(3, 31, 45, 0.54);
              }
              .ant-input {
                height: 100% !important;
                max-height: 100% !important;
                min-height: 100% !important;
                border: none !important;
              }
            }
          }
          .mt-3x {
            margin-top: 36px;
          }
          .f-checkbox {
            font-weight: 700;
            justify-content: center;
            justify-content: center;
            display: flex;
            .ant-checkbox-wrapper {
              display: flex;
              align-items: center;
              span {
                font-size: 16px !important;
                &:last-child {
                  color: #fff !important;
                }
              }
              .ant-checkbox {
                top: 0px !important;
              }
            }
          }
        }
        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          .section-modal {
            width: 100%;
            font-weight: 300;
            .wrapper-modal {
              padding: 56px;
              border-radius: 15px;
              background-color: #150056;
              background-image: none;
              backdrop-filter: blur(14px);
              color: #fff;
              .title {
                font-family: FiraSans;
                font-size: 32px;
                font-weight: 700;
                text-align: center;
              }
              .body {
                font-size: 16px;
                font-weight: 400;
                text-align: center;
                margin-top: 5px;
                margin-bottom: 5px;
              }
            }
          }
        }
        .button-field {
          margin-top: 30px;
          .section-modal {
            .wrapper-modal {
              padding: 35px;
              button {
                text-transform: uppercase;
                &:hover {
                  letter-spacing: 2px;
                }
              }
            }
          }
        }
      }
    }
  `;
  return { styleModal };
};
enum enumBGColors {
  Default = '#ffffff',
  Primary = '#04CAAE',
  Secondary = 'rgba(255, 255, 255, 0.2)',
}

enum enumColors {
  Default = '#fff',
  Primary = '#ffffff',
}

export const ButtonStyled = styled(Button)`
  height: 55px !important;
  margin: 15px 0;
  text-transform: uppercase;
  font-size: 17px !important;

  &.ant-btn {
    :disabled {
      font-size: 13px;
      font-weight: bold;
    }
    &:not([disabled]) {
      text-align: center;
      background: ${(props) => (props.type === 'default' ? enumBGColors.Secondary : enumBGColors.Primary)};
      border-color: transparent;
      color: ${(props) => (props.type === 'default' ? enumColors.Primary : enumColors.Primary)};
      font-size: 13px;
      font-weight: bold;
      padding: 0 12px;
      letter-spacing: -0.4px;

      &:hover,
      &:active,
      &:focus {
        background: ${(props) => (props.type === 'default' ? enumBGColors.Secondary : enumBGColors.Primary)};
        border-color: transparent;
        color: ${(props) => (props.type === 'default' ? enumColors.Primary : enumColors.Primary)};
      }

      &:hover {
        letter-spacing: 0.4px;
        padding-left: 8px;
      }
    }

    &.btn-add {
      width: 156px;

      &:hover {
        letter-spacing: 0px;
      }
    }

    &.btn-view-map {
      width: 132px;
    }

    &.btn-dark {
      width: 106px;
    }
    &.btn-view-addresses {
      font-size: 14px !important;
      text-transform: capitalize;
      border-radius: 5px;
      width: 100%;
      height: 58px !important;
      display: flex;
      gap: 4px;
      justify-content: center;
      align-items: center;
      &:disabled {
        background: #04caae;
        color: #fff;
        border-color: #04caae;
        opacity: 0.5;
      }
    }
  }

  &.btn-add,
  &.btn-exclude {
    font-weight: 700 !important;
    font-size: 13px !important;
    border-radius: 2px !important;
    width: 112px !important;
    height: 30px !important;
    text-transform: initial !important;
  }
  &.btn-exclude {
    background-color: #a8b0c4 !important;
  }
  &.btn-add {
    background-color: #04caae !important;
    margin-right: 11px;
  }
`;
export const ListTag = styled.div`
  display: flex;
  flex-wrap: wrap;
  .tag {
    color: #333333;
    border-radius: 15px;
    font-size: 13px;
    background-color: #04caae;
    font-weight: 400;
    padding: 7px 25px 7px 15px;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
    &.exclude {
      background-color: #a8b0c4;
    }
    .close-ic {
      position: absolute;
      width: 12px;
      height: 12px;
      top: 11px;
      right: 8px;
      cursor: pointer;
    }
    strong {
      font-weight: 700;
    }
  }
`;

export const SelectV2WrapperStyled = styled.div`
  min-width: 76px;
  margin-right: 17px;
`;

interface DatePickerStyledProps {
  isError?: boolean;
}

export const DatePickerStyled = styled(DatePicker)<DatePickerStyledProps>`
  &.ant-picker {
    border-color: ${({ isError }) => (isError ? theme.text.errorColor : theme.text.grayColor)}!important;
  }
  .ant-picker-input {
    input {
      &::placeholder {
        color: rgba(0, 0, 0, 0.85) !important;
      }
    }
  }
  .ant-picker-input > input[disabled] {
    &::placeholder {
      color: rgba(0, 0, 0, 0.25) !important;
    }
  }
  &.ant-picker.ant-picker-disabled {
    opacity: 0.8;
  }
`;
