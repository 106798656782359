import { css } from '@emotion/react';
import { navigate } from 'gatsby';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BaseModal from '@/components/Modal';
import AppAction from '@/reducers/app/action';
import { RootState } from '@/reducers/model';
import RoutePaths from '@/utils/routes';
import AppButtonV2 from '@/components/AppButtonV2';
const WarningMessageNodataModal: React.FunctionComponent = () => {
  const visible = useSelector((state: RootState) => state?.app?.visibleMessageNoDataModal);
  const dispatch = useDispatch();
  const pathName = window.location.pathname;
  if (![RoutePaths.AGENT_REPORT, RoutePaths.SMART_ACTIONS].some((v) => pathName.includes(v))) return null;
  return (
    <div>
      <BaseModal
        getContainer={false}
        visible={visible}
        footer={false}
        centered
        closable={false}
        css={css`
          .ant-modal-content {
            background: transparent;
            box-shadow: none;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            margin-top: 40px;
            .ant-modal-body {
              .content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: relative;
                .section-modal {
                  max-width: 480px;
                  width: 100%;
                  font-weight: 300;
                  .wrapper-modal {
                    padding: 35px 30px 35px 30px;
                    border-radius: 15px;
                    background-color: #150056;
                    background-image: none;
                    backdrop-filter: blur(14px);
                    color: #fff;
                    .title {
                      font-family: FiraSans;
                      font-size: 32px;
                      font-weight: 700;
                      text-align: center;
                      margin-bottom: 15px;
                    }
                    .body {
                      font-size: 20px;
                      font-weight: 500;
                      text-align: center;
                      margin-top: 5px;
                      margin-bottom: 5px;
                    }
                  }
                }
              }
              .button-field {
                margin-top: 30px;
                .section-modal {
                  .wrapper-modal {
                    padding: 35px;
                    button {
                      text-transform: uppercase;
                      &:hover {
                        letter-spacing: 2px;
                      }
                    }
                  }
                }
              }
            }
            .ant-modal-close-x {
              display: none;
            }
          }
        `}
      >
        <div className="content">
          <div className="section-modal">
            <div className="wrapper-modal">
              <div className="user-avatar">
                <p className="body">There are no mailing lists yet. Please upload or draw a mailing list.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="content button-field">
          <div className="section-modal">
            <div className="wrapper-modal">
              <AppButtonV2
                size="large"
                width="100%"
                block
                onClick={() => {
                  dispatch({
                    type: AppAction.VISIBLE_MESSAGE_NODATA_MODAL,
                    payload: false,
                  });
                  navigate(`${RoutePaths.MAILING_LISTS}`);
                }}
              >
                Go to Mailing List
              </AppButtonV2>
            </div>
          </div>
        </div>
      </BaseModal>
    </div>
  );
};

export default WarningMessageNodataModal;
