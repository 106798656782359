import React from 'react';
import styled from '@emotion/styled';

interface HyperlinkProps {
  url: string;
  children: React.ReactNode;
  queryParams?: { [key: string]: string | number };
  style?: React.CSSProperties; //  prop style
  classNames?: string;
}

const StyledLink = styled.a`
  color: #150056;
  text-decoration: underline;
  font-weight: 400;
  &:hover,
  &:active,
  &:focus,
  &:visited {
    text-decoration: underline;
    color: #150056;
  }
`;

const toQueryString = (params: { [key: string]: string | number } = {}) => {
  return Object.entries(params)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
};

const Hyperlink: React.FC<HyperlinkProps> = ({ url, children, queryParams, style, classNames }) => {
  const separator = url.includes('?') ? '&' : '?';
  const queryString = queryParams ? `${separator}${toQueryString(queryParams)}` : '';
  const finalUrl = url + queryString;

  return (
    <StyledLink href={finalUrl} style={style} className={classNames ?? ''} target="_blank" rel="noopener noreferrer">
      {children}
    </StyledLink>
  );
};

export default Hyperlink;
