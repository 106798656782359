import styled from '@emotion/styled';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import queryString from 'query-string';
import React, { useMemo } from 'react';
import isEqual from 'react-fast-compare';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useUserRole } from '@/hooks/useUserRole';
import MailingAction from '@/reducers/mailing/action';
import { RootState } from '@/reducers/model';
import { limit_mailing_list } from '@/utils/constant';
import RoutePaths from '@/utils/routes';

interface NavigationType {
  to: string;
  name: string;
  icon: string;
  skipQuery?: boolean;
}

const SidebarMenu: React.FC<{
  className?: string;
}> = React.memo(() => {
  const mailingListsCombine = useSelector((state: RootState) => state.mailing.mailingListsCombine);
  const myProfile = useSelector((state: RootState) => state.auth.myProfile);
  const dispatch = useDispatch();
  const { isAgent } = useUserRole();

  function createNavItem(v: { id: string; name?: string }): NavigationType {
    const { pathname } = window.location;
    const URL_PATH_NAME = pathname.includes(RoutePaths.MY_REPORT_DYNAMIC)
      ? RoutePaths.MY_REPORT
      : RoutePaths.AGENT_REPORT;
    return {
      to: `${URL_PATH_NAME}/${v.id}`,
      name: v.name ?? '',
      icon: '',
    };
  }

  const updatedNavigationData = useMemo(() => {
    const result: NavigationType[] = [...mailingListsCombine?.results?.map((v) => createNavItem(v))];
    if (isAgent) {
      result.unshift({
        to: RoutePaths.MAILING_LISTS,
        name: 'Dashboard',
        icon: '',
        skipQuery: true,
      });
    }
    return result;
  }, [mailingListsCombine, myProfile]);

  const CustomLink = ({ to, children, activeClassName, ...rest }) => {
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const ssid = queryParams.ssid;
    const user = queryParams.user;

    // Check if the link is not for the dashboard and ssid exists
    const shouldAppendSsid = ssid && !to.includes(RoutePaths.MAILING_LISTS);

    // Construct the new URL
    let modifiedTo = to;
    if (shouldAppendSsid) {
      modifiedTo += `?ssid=${ssid}`;
      if (user) {
        modifiedTo += `&user=agent`;
      }
    }
    const isActive = (pathname, to) => {
      // Compare the base paths, ignoring any query parameters
      return pathname?.split('?')?.[0] === to.split('?')?.[0];
    };
    const activeClass = isActive(location.pathname, to) ? activeClassName : '';

    return (
      <Link to={modifiedTo} className={activeClass} {...rest}>
        {children}
      </Link>
    );
  };

  const handleInfiniteLoad = () => {
    if (mailingListsCombine?.count > mailingListsCombine?.results?.length) {
      const nextPage = Math.ceil(mailingListsCombine?.results?.length / limit_mailing_list) + 1; // calculate load next page
      dispatch({ type: MailingAction.LIST_MAILING_LIST_COMBINE, payload: { page: nextPage } });
    }
  };
  return (
    <SidebarMenuWrapper>
      <SidebarMenuNav id="menuNav">
        <InfiniteScroll
          dataLength={mailingListsCombine?.results?.length || 0}
          next={handleInfiniteLoad}
          loader={false}
          hasMore={!mailingListsCombine.isFetching}
          scrollableTarget="menuNav"
        >
          {updatedNavigationData.map((item, i) => {
            return (
              <CustomLink to={item.to} key={i} activeClassName="active" partiallyActive>
                <div className="text-block">
                  <span className="fa-icon">{item.icon}</span>
                  {item.name}
                </div>
              </CustomLink>
            );
          })}
        </InfiniteScroll>
      </SidebarMenuNav>
    </SidebarMenuWrapper>
  );
}, isEqual);

export default SidebarMenu;

const SidebarMenuWrapper = styled.section`
  position: sticky;
  top: 114px;
  display: flex;
  overflow: auto;
  margin-right: 18px;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;
  flex: 0 0 auto;
  @media (max-width: ${({ theme }) => theme.media.desktops}px) {
    position: initial;
    overflow: auto;
    flex-direction: row;
    width: 100%;
    margin-bottom: 20px;
  }
`;

const SidebarMenuNav = styled.nav`
  @media (max-width: ${({ theme }) => theme.media.desktops}px) {
    display: flex;
    .infinite-scroll-component {
      display: flex;
    }
  }
  overflow-y: auto;
  max-height: 80vh;

  a {
    display: flex;
    padding: 16px;
    align-items: stretch;
    flex: 0 0 auto;
    background-color: #e7edf3;
    transition: all 400ms ease;
    cursor: pointer;
    color: #333;
    min-width: 201px;
    max-width: 265px;
    border-left: 6px solid #e7edf3;
    &.active,
    &#id-active,
    &:hover {
      border-left: 6px solid #150056;
    }
    @media (max-width: ${({ theme }) => theme.media.desktops}px) {
      margin-right: 4px;
      padding: 10px 30px;
      align-items: center;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      max-width: 100%;
      border-left: none;
      &.active,
      &:hover {
        border-left: none;
        background-color: #333;
        color: #fff;
      }
    }
    .text-block {
      display: flex;
      align-items: center;
      > .fa-icon {
        display: inline;
        margin-right: 10px;
        font-family: 'Fa 300', sans-serif;
        font-size: 24px;
        line-height: 1.2;
      }
    }
  }
`;
